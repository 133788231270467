import { ni18nConfig } from '@mainApp/ni18n.config';
import { Auth } from '@mainApp/src/components/auth/Auth';
import { Navbar } from '@mainApp/src/components/navbar/Navbar';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ssrHub } from '@mainApp/src/services';
import { observer } from 'mobx-react-lite';
import Head from 'next/head';
import { loadTranslations } from 'ni18n';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default observer(function LoginPage() {
  const { t } = useTranslation('auth');
  const { dimensionsStore, authStore } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.authStore,
  ]);

  useEffect(() => {
    authStore.setLoginMode(true);
  }, []);

  return (
    <main className="dedicated-page group flex flex-1 flex-col px-[12px] py-[24px]">
      <Head>
        <title>{t('logIn')}</title>
      </Head>
      <div className={dimensionsStore.isMobile ? 'hidden' : ''}>
        <Navbar />
      </div>
      <div className="flex flex-1 justify-center md:items-center">
        <Auth
          dedicatedPageMode
          onCloseBtnClick={() => {
            // it's an action to close the bottomsheet
            console.info('on close btn click');
          }}
        />
      </div>
    </main>
  );
});

export async function getServerSideProps(props: any) {
  // TODO: maybe use more correct lang selector from the req header?
  const locale = props.req.cookies.user_locale || props.locale;
  ssrHub.setDataProp('lang', locale);
  const res = {
    props: {
      ...(await loadTranslations(ni18nConfig, locale, [
        'common',
        'home',
        'create-community',
        'auth',
      ])),
    },
  };

  return res;
}
